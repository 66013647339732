<template>
  <div class="game">
    <div class="row">
      <div class="col s6" v-for="(player,pN) in hub.players" :key="pN">
        <div class="row flex-wrap justify-center">
          <div class="col s12 player-name">
            <div class="card-panel blue darken-1 white-text">
              {{ player.name }}
            </div>
          </div>
          <div class="field col s12 flex">
            <div class="card-panel blue lighten-4 flex flex-direction-column">
              <div class="row justify-center">
                <div class="cell cellH blue lighten-4"></div>
                <div class="cell cellH blue lighten-4" v-for="l in letters" :key="'cell'+0+'-'+l">{{ l }}</div>
              </div>
              <div class="row justify-center" v-for="(row,y) in hub.fields[pN]" :key="'row'+y">
                <div class="cell cellH blue lighten-4">{{ y + 1 }}</div>
                <div class="cell"
                     :class="{empty:hub.fields[pN][y][x]==='empty',
                     ship:hub.fields[pN][y][x]==='ship',
                     bomb:hub.fields[pN][y][x]==='bomb',
                     bursted:hub.fields[pN][y][x]==='bursted',
                     alive:hub.fields[pN][y][x]==='shipAlive',}"
                     v-for="(exist,x) in row" :key="'cell'+x+'-'+y" @click="trigger(pN,x,y)"></div>
              </div>
            </div>
            <div class="card-panel blue lighten-5 flex flex-direction-column">
              Ходы:
              <div class="targets  flex flex-direction-column" v-html="hub.targets[pN]"
                   style="height:450px;overflow-y: scroll" :ref="'target'+pN"></div>
            </div>
          </div>
          <div class="col s12 flex">
            <div class="card-panel red lighten-1" v-if="noShips[pN]">
              Не осталось ни одного скрытого корабля!
            </div>
          </div>
          <div class="buttons row col s12 justify-center flex flex-wrap" v-if="activeField===pN">
            <div class="col s12">
              <div class="card-panel blue lighten-5 flex flex-direction-column">
                Вы попали в {{where}}!
              </div>
            </div>
            <div class="col s6">
              <a class="waves-effect waves-light btn green" @click="right(pN)">Ответ верный</a>
            </div>
            <div class="col s6">
              <a class="waves-effect waves-light btn red" @click="wrong(pN)">Ответ неверный</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col s3"></div>
      <router-link class="col s6 waves-effect waves-light btn cyan" :to="{name:'setup'}">Главная</router-link>
      <div class="col s3"></div>
    </div>
  </div>

</template>

<script>
import eventHub from "@/eventHub";
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'game',
  computed: {
    ...mapGetters("Config", {getConfig: "getConfig",}),
  },
  data() {
    return {
      dim: 10,//hub.players[this.$route.params.playerN-1].field.length,
      hub: eventHub,
      letters: ['А', 'Б', 'В', 'Г', 'Д', 'Е', 'Ж', 'З', 'И', 'К',],
      activeField: '',
      activeCell: '',
      activeShip: '',
      where: '',
      noShips: [false,false],
    };
  },
  methods: {
    ...mapActions("Config", ["save","load","saveStep"]),
    trigger(pN, x, y) {
      if (this.activeField === '' && !this.hub.fields[pN][y][x]) {
        // console.log(pN, x, y, this.hub.players[pN].field[y][x], this.hub.fields[pN][y][x],this.$refs['target' + pN]);
        this.hub.targets[pN] += '<span>' + this.letters[x] + '-' + (y+1) + '</span>';
        setTimeout(()=>{this.$refs['target' + pN][0].scrollTop = this.$refs['target' + pN][0].scrollHeight-this.$refs['target' + pN][0].clientHeight},200);
        this.activeField = pN;
        this.activeCell = {x, y};
        if (this.hub.players[pN].field[y][x] === 'ship') {
          //search for ship
          let flag = false;
          for (let size = 0; size < this.hub.players[pN].ships.length && !flag; size++) {
            for (let sn = 0; sn < this.hub.players[pN].ships[size].length && !flag; sn++) {
              for (let sc = 0; sc < this.hub.players[pN].ships[size][sn].length && !flag; sc++) {
                if (this.hub.players[pN].ships[size][sn][sc].x === x && this.hub.players[pN].ships[size][sn][sc].y === y) {
                  flag = true;
                  this.activeShip = {size, sn, sc};
                  for (let cell = 0; cell < this.hub.players[pN].ships[size][sn].length; cell++)
                    this.$set(this.hub.fields[pN][this.hub.players[pN].ships[size][sn][cell].y], this.hub.players[pN].ships[size][sn][cell].x, 'ship');
                  console.log('here is a ship');
                  this.where='корабль';
                }
              }
            }
          }
          this.noShips[pN]=!this.isThereAnyShip(pN);
          console.log('noShips '+this.noShips[pN]);
        }//ship
        if (this.hub.players[pN].field[y][x] === 'bomb') {
          //this.hub.fields[pN][y][x]='bomb';
          this.$set(this.hub.fields[pN][y], x, 'bomb');
          console.log('here is a bomb');
          this.where='мину ('+this.hub.bombOrder[this.hub.players[pN].bombs.shift()]+')';
        }
        if (!this.hub.players[pN].field[y][x]) {
          //this.hub.fields[pN][y][x]='empty';
          this.$set(this.hub.fields[pN][y], x, 'empty');
          this.activeField = '';
          this.activeCell = '';
          this.activeShip = '';
          console.log('here is a blue sea');
        }
        this.saveStep(this.hub.$data);
      }
    }
    ,
    right(pN) {
      if (this.activeShip !== '') {
        let size = this.activeShip.size;
        let sn = this.activeShip.sn;
        for (let cell = 0; cell < this.hub.players[pN].ships[size][sn].length; cell++) {
          this.hub.fields[pN][this.hub.players[pN].ships[size][sn][cell].y][this.hub.players[pN].ships[size][sn][cell].x] = 'bursted';
        }
      }
      if (this.hub.players[pN].field[this.activeCell.y][this.activeCell.x] === 'bomb') {
        this.hub.players[pN].field[this.activeCell.y][this.activeCell.x] = 'empty';
      }
      this.activeField = '';
      this.activeCell = '';
      this.activeShip = '';
    }
    ,
    wrong(pN) {
      if (this.activeShip !== '') {
        let size = this.activeShip.size;
        let sn = this.activeShip.sn;
        for (let cell = 0; cell < this.hub.players[pN].ships[size][sn].length; cell++) {
          this.hub.fields[pN][this.hub.players[pN].ships[size][sn][cell].y][this.hub.players[pN].ships[size][sn][cell].x] = 'shipAlive';
        }
      }
      if (this.hub.players[pN].field[this.activeCell.y][this.activeCell.x] === 'bomb') {
        this.hub.players[pN].field[this.activeCell.y][this.activeCell.x] = 'bursted';
      }
      this.activeField = '';
      this.activeCell = '';
      this.activeShip = '';
    },
    isThereAnyShip(pN){
      let flag=false;
      for (let x = 0; x < this.hub.players[pN].field.length && !flag; x++) {
        for (let y = 0; y < this.hub.players[pN].field.length && !flag; y++) {
          if (!this.hub.fields[pN][y][x] && this.hub.players[pN].field[y][x]==='ship'){
            flag=true;
          }
        }
      }
      return flag;
    },
  },
  mounted() {
    if (!window.localStorage.getItem('config')){
      this.save(this.hub.$data);
      console.log('hub$data',this.hub.$data);
      //this.save(JSON.stringify(eventHub.$data));
    }
  }
}
</script>

<style scoped lang="scss">
.row {
  display: flex;
  margin-bottom: 0;
}

.max-height-100 {
  max-height: 100%;
}


.player-name {
  text-align: center;
  font-weight: bolder;
}
</style>